












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = false;
  public error: any = null;
  public email: string = '';
  public password: string = '';

  public async created() {
    const msgkey = this.$route.params.msg;
    if (msgkey === 'LOGOUT_SUCCESS') {
      this.$q.notify('Vous êtes déconnecté');
    }
    if (this.$route.query.email !== undefined) {
      this.email = this.$route.query.email;
    }
  }

  public handleSubmit() {
    this.error = null;
    if (this.password.length > 0) {
      this.loading = true;
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      }).catch((error: any) => {
        this.error = 'Email ou mot de passe invalide';
        this.loading = false;
      });
    }
  }
}
